@font-face {
  font-family: 'Suisse';
  src: url("3d5c5215a35c04f00993c54b86736577.woff") format('woff'), url("81f313c9a04fb10f738c85c9a56bdceb.woff2") format('woff2');
  font-weight: normal;
  font-style: normal;
}
*:focus {
  outline: none !important;
}
html {
  font-size: 1.875vw;
  color: #0f0;
  background-color: #777;
}
@media (max-width: 640px) {
  html {
    font-size: 9.6vw;
  }
}
body {
  min-height: 100vh;
  position: relative;
  padding: 0.611111111111111rem 0.833333333333333rem 0.277777777777778rem !important;
  font-family: Suisse, Helvetica, sans-serif;
}
canvas {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100vh;
  height: 100%;
  width: 100vw;
}
header {
  position: relative;
  display: flex;
  justify-content: space-between;
  z-index: 10;
  width: 100%;
  font-size: 2.666666666666667rem;
  line-height: 1;
  letter-spacing: -0.04em;
}
@media (max-width: 640px) {
  header {
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 1.388888888888889rem;
    line-height: 0.9;
  }
}
.x {
  width: 2.083333333333333rem;
  height: 2rem;
  background-image: url("fdb90945a7ca067ab83eb9ff93083e62.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  left: 50%;
  top: 0.138888888888889rem;
  transform: translateX(-50%);
}
@media (max-width: 640px) {
  .x {
    position: static;
    transform: none;
    left: auto;
    top: auto;
    width: 1.5rem;
    height: 1.444444444444444rem;
    margin: 0.972222222222222rem 0;
  }
}
.layout {
  height: 100%;
  display: flex;
  flex-direction: column;
}
main {
  flex: 1;
}
footer {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 10;
  line-height: 1.638888888888889;
}
@media (max-width: 640px) {
  footer {
    font-size: 0.555555555555556rem;
    line-height: 1.6;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}
.bang {
  display: flex;
  align-items: center;
}
.circle {
  width: 2.166666666666667rem;
  height: 2.166666666666667rem;
  border-radius: 9999px;
  background: #0f0;
  margin-right: 1rem;
}
@media (max-width: 640px) {
  .circle {
    margin-right: 0.555555555555556rem;
    width: 1.194444444444444rem;
    height: 1.194444444444444rem;
  }
}
.festival {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  text-align: center;
}
@media (max-width: 640px) {
  .festival {
    position: static;
    left: auto;
    top: auto;
    transform: none;
    margin: 0.833333333333333rem 0;
  }
}
.dev {
  text-align: right;
}
@media (max-width: 640px) {
  .dev {
    text-align: center;
  }
}
